import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isBrowser } from 'utils/browser';
import Layout from 'components/Layout';
import EventCardAnimation from 'components/EventCardAnimation';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';

export default function Logout() {
  if (!isBrowser) return null;
  const { loginWithRedirect } = useAuth0();
  const { search } = useLocation();
  const searchParams = parse(search);
  loginWithRedirect({
    redirectUri: `${process.env.GATSBY_AUTH0_CALLBACK}${search.returnTo || '/'}`,
    appState: searchParams
  });
  return (
    <Layout>
      <EventCardAnimation />
    </Layout>
  );
}
